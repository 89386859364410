export enum WidgetName {
  accidents_count_by_hour = 'accidents_count_by_hour',
  injured_count_per_age_group = 'injured_count_per_age_group',
  most_severe_accidents = 'most_severe_accidents',
  most_severe_accidents_table = 'most_severe_accidents_table',
  accidents_heat_map = 'accidents_heat_map',
  street_view = 'street_view',
  accident_count_by_severity = 'accident_count_by_severity',
  accident_count_by_accident_type = 'accident_count_by_accident_type',
  accident_count_by_accident_year = 'accident_count_by_accident_year',
  injured_count_by_accident_year = 'injured_count_by_accident_year',
  accident_count_by_day_night = 'accident_count_by_day_night',
  head_on_collisions_comparison = 'head_on_collisions_comparison',
  head_on_collisions_comparison_percentage = 'head_on_collisions_comparison_percentage',
  vision_zero_2_plus_1 = 'vision_zero_2_plus_1',
  vision_zero_10_50_90 = 'vision_zero_10_50_90',
  vision_zero_bike = 'vision_zero_bike',
  top_road_segments_accidents_per_km = 'top_road_segments_accidents_per_km',
  accident_count_by_road_light = 'accident_count_by_road_light',
  accident_count_by_driver_type = 'accident_count_by_driver_type',
  accident_count_by_car_type = 'accident_count_by_car_type',
  injured_accidents_with_pedestrians = 'injured_accidents_with_pedestrians',
  injured_count_by_severity = 'injured_count_by_severity',
  killed_and_injured_count_per_age_group = 'killed_and_injured_count_per_age_group',
}
